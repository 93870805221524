<template>
  <div class="roles-manage-page">
    <x-table :no-data-text="CA('rolesManage_check') ? '暂无数据' : '暂无数据查询权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @page-size-change="pageSizeChange"></x-table>

    <Modal v-model="modal.show" :title="modal.title" @on-visible-change="modalShow">
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="角色名称" prop="name">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="备注" prop="tips">
          <Input v-model="form.tips"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <authority-assignment v-model="authorityModalShow" :roleId="roleId" :menuType="menuType" @config-success="configSuccess"></authority-assignment>

    <sort-modal v-model="sortModalShow" :roleId="roleId" :menuType="menuType"></sort-modal>
  </div>
</template>

<script>
import authorityAssignment from './authorityAssignment'
import sortModal from './sortModal'
import { mapState } from 'vuex'
export default {
  name: '',
  components: {
    authorityAssignment,
    sortModal,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '角色名称',
            key: 'name',
          },
          {
            title: '备注',
            key: 'tips',
          },
          {
            title: '操作',
            width: 360,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('authority_config') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.configAuthority(row.id, 'wfpt')}
                    >
                      角色权限
                    </a>
                  )}
                  {this.CA('authority_config') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.configAuthority(row.id, 'wfpt_app')}
                    >
                      app权限
                    </a>
                  )}
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.menusSort(row.id, 'wfpt')}
                  >
                    菜单排序
                  </a>
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.menusSort(row.id, 'wfpt_app')}
                  >
                    app菜单排序
                  </a>
                  {this.CA('rolesManage_edit') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('rolesManage_delete') && !row.version && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: '新增角色',
            ca: 'rolesManage_add',
          },
          width: 200,
          filterBox: [
            {
              conditionName: '角色名称',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: '',
        submitLoading: false,
      },

      form: {
        name: '',
        tips: '',
      },
      rules: {
        name: [{ required: true, message: '请填写角色名称' }],
      },
      search_data: {},

      authorityModalShow: false,
      roleId: '',

      sortModalShow: false,
      menuType: 'wfpt',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    search(data) {
      this.search_data = data
      this.config.page.pageNo = 1
      this.getList()
    },

    add() {
      this.modal = {
        show: true,
        title: '新增角色',
        submitLoading: false,
      }
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA('rolesManage_check')) return
      this.table.loading = true
      this.$post(this.$api.ROLE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        roleType: this.user.category + 1,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.config.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.id = row.id
      this.modal = {
        show: true,
        title: '编辑角色',
        submitLoading: false,
      }
    },

    del(id) {
      this.$post(this.$api.ROLE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success('删除成功')
        this.getList()
      })
    },

    configAuthority(id, menuType) {
      this.roleId = id
      this.authorityModalShow = true
      this.menuType = menuType
    },

    configSuccess() {
      this.authorityModalShow = false
    },

    menusSort(id, menuType) {
      this.roleId = id
      this.sortModalShow = true
      this.menuType = menuType
    },

    modalShow(visible) {
      if (visible) return
      this.form = {
        name: '',
        tips: '',
      }
      this.$refs.form.resetFields()
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        this.modal.submitLoading = true
        this.form.roleType = this.form.roleType
          ? this.form.roleType
          : this.user.category + 1
        this.$post(this.form.id ? this.$api.ROLE.EDIT : this.$api.ROLE.ADD, {
          ...this.form,
        })
          .then(() => {
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.roles-manage-page {
  width: 100%;
  height: 100%;
}
</style>